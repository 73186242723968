<template>
  <div>
    <a-card title="入库订单详情">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>

      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <div style="font-size: 36px; font-weight: 400; text-align: center">入库订单</div>
          <a-row>
            <a-col :span="24" style="text-align: right">
              <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
            </a-col>
            <a-col :span="6">仓库: {{ orderItem.warehouse_item.name }}</a-col>
            <a-col :span="6">到货日期: {{ orderItem.arrival_date }}</a-col>
            <a-col :span="6">创建时间: {{ orderItem.create_time }}</a-col>
            <a-col :span="6">备注: {{ orderItem.remark }}</a-col>
            <a-col :span="24">
              附件:
              <a
                v-for="fileItem in orderItem.receipt_file_items"
                :href="fileItem.file"
                :key="fileItem.id"
                style="margin-right: 16px; display: block"
              >
                {{ fileItem.name }}
              </a>
            </a-col>
          </a-row>
        </a-spin>

        <div style="margin-top: 16px">
          <table border="1" style="width: 100%">
            <tr>
              <th>序号</th>
              <th>产品名称</th>
              <th>产品编号</th>
              <th>英文名称</th>
              <th>批次编号</th>
              <th>数量</th>
              <th>单位</th>
              <th>包装规格</th>
            </tr>
            <tr v-for="(item, index) in materialItems" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.material_item.name }}</td>
              <td>{{ item.material_item.number }}</td>
              <td>{{ item.material_item.english_name }}</td>
              <td>{{ item.batch_number }}</td>
              <td>{{ item.total_quantity }}</td>
              <td>{{ item.material_item.unit }}</td>
              <td>{{ item.material_item.spec }}</td>
            </tr>
            <tr>
              <td>合计:</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ totalQuantity }}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import { stockInOrderRetrieve, stockInOrderMaterials } from "@/api/order";
import JsBarcode from "jsbarcode";
import { nextTick } from "process";
import NP from "number-precision";

export default {
  components: {},
  data() {
    return {
      orderItem: {},
      materialItems: [],

      orderLoading: false,
      materialLoading: false,
    };
  },
  computed: {
    totalQuantity() {
      let totalQuantity = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.total_quantity);
      }
      return totalQuantity;
    },
    totalWeight() {
      let totalWeight = 0;
      for (let item of this.materialItems) {
        if (item.material_item.weight) {
          totalWeight = NP.plus(totalWeight, NP.times(item.total_quantity, item.material_item.weight));
        }
      }
      return totalWeight;
    },
    totalVolume() {
      let totalVolume = 0;
      for (let item of this.materialItems) {
        if (item.material_item.volume) {
          totalVolume = NP.plus(totalVolume, NP.times(item.total_quantity, item.material_item.volume));
        }
      }
      return totalVolume;
    },
  },
  methods: {
    times(value1, value2) {
      value1 = value1 ? value1 : 0;
      value2 = value2 ? value2 : 0;
      return NP.times(value1, value2);
    },
  },
  mounted() {
    const orderId = this.$route.query.id;
    this.orderLoading = true;
    this.materialLoading = true;

    stockInOrderRetrieve({ id: orderId })
      .then((data) => {
        this.orderItem = data;
        nextTick(() => {
          if (data.external_number) {
            new JsBarcode(this.$refs.jsbarcode, data.external_number, {
              format: "CODE128",
              width: 2,
              height: 35,
              displayValue: true,
              background: "#ffffff",
              lineColor: "#000000",
            });
          }
        });
      })
      .finally(() => {
        this.orderLoading = false;
      });

    stockInOrderMaterials({ id: orderId })
      .then((data) => {
        this.materialItems = data;
      })
      .finally(() => {
        this.materialLoading = false;
      });
  },
};
</script>

<style scoped></style>
